import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HowDoesItWorkComponent } from './how-does-it-work/how-does-it-work.component';
import { ContactComponent } from './contact/contact.component';
import { FormComponent } from './form/form.component';
import { VendorComponent } from './vendor/vendor.component';
import { SendgridServiceService } from './sendgrid-service.service';
//import { NgxPageScrollModule } from 'ngx-page-scroll';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HowDoesItWorkComponent,
    ContactComponent,
    FormComponent,
    VendorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    //NgxPageScrollModule
  ],
  providers: [SendgridServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
