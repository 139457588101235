import { Injectable } from '@angular/core';
import{ HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer 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'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SendgridServiceService {
  apiURL: string = "https://api.sendgrid.com/v3/mail/send";

  constructor(private httpClient: HttpClient) { }

  public SendMail(from: string, email: string, content: string)
  {
    let body = this.createMailBody(from, email, content);

    return this.httpClient.post(this.apiURL, body, httpOptions);
  }

  createMailBody(from: string, email: string, content: string)
  {
    let body = '{"personalizations":[{"to":[{"email":"gael@labarque.com","name":"John Doe"}],"subject":"Hello, World!"}],"content": [{"type": "text/plain", "value": "Heya!"}],"from":{"email":"sam.smith@example.com","name":"Sam Smith"},"reply_to":{"email":"sam.smith@example.com","name":"Sam Smith"}}';

    return body;
  }
}
