import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SendgridServiceService } from '../sendgrid-service.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  messageForm: FormGroup;
  submitted = false;
  success = false;

  constructor(private formBuilder: FormBuilder, private sendgridService: SendgridServiceService) { }

  ngOnInit() {
    this.messageForm = this.formBuilder.group({
      company: ['', Validators.required],
      companyNumber: ['', Validators.required],
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      type: ['', Validators.required],
      subject: [''],
      message: ['']
    });
  }

  onSubmit()
  {
    this.submitted = true;

    if (this.messageForm.invalid){
      return;
    }

    this.sendgridService.SendMail("ok", "ok", "ok").subscribe((res) => {
      console.log(res);
    });


    this.success = true;
  }

}
